@import '../../common/sass/variables';

.bin-layout {
  padding: 25px 100px 0 100px;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $bg-primary;

  &__header {
    &-top {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }
  }

  &__title {
    font-size: 42px;
    font-family: $font-family-3;
    font-weight: 700;
  }

  &__back-button {
    margin-right: 37px;
  }

  &__bottom {
    margin-bottom: 50px;
  }
}

@media (max-width: $breakpoint-xxl) {
  .bin-layout {
    padding: 25px 100px 0 100px;
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: $bg-primary;

    &__header {
      &-top {
        margin-bottom: 12px;
      }
    }

    &__back-button {
      margin-right: 24px;
    }

    &__title {
      font-size: 36px;
      margin: 20px 0;
    }

    &__bottom {
      margin-bottom: 30px;
    }
  }
}
@media (max-width: $breakpoint-md) {
  .bin-layout {
    padding: 20px 20px 0 20px;

    &__bottom {
      margin-bottom: 20px;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .bin-layout {
    padding: 0 20px;

    &__bottom {
      margin-bottom: 10px;
    }
  }
}
