@import '../../common/sass/variables';

.note-card {
  padding: 8px 16px;
  margin: 16px;
  border-radius: 15px;
  background-color: $color-white;
  box-shadow: 0 6px 18px $color-box-shadow;
  position: relative;
  cursor: pointer;

  &--selected {
    border: 2px solid $color-sienna;
  }

  &--bin {
    &:hover {
      .note-card__select-btn {
        background-color: $color-primary;
      }
    }
  }

  &--selected-bin {
    background-color: $color-zircon;
    .note-card__select-btn {
      background-color: $color-primary;
    }

    border: 2px solid $color-primary;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 44px;

    span {
      font-size: 21px;
      font-weight: 600;
      color: $color-black;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &--empty {
      span {
        font-weight: 500;
        color: $color-grey;
      }
    }
  }

  &__select-btn {
    position: absolute;
    top: -15px;
    left: -15px;
    height: auto;
    border-radius: 50%;
    background-color: $color-sienna;
    cursor: pointer;

    svg {
      width: 16px;
      height: 16px;
      padding: 7px;
      path {
        stroke: #fff;
      }
    }
  }

  &__pin-btn {
    align-items: center;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 1px 12px $color-box-shadow;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__content {
    margin: 8px 0;
    overflow-wrap: anywhere;
    overflow: hidden;
    white-space: pre-wrap;
    max-height: 200px;
    min-height: 50px;

    .text-input__input {
      font-size: 18px;
      color: $color-night-rider;
      line-height: 26px;
    }

    .text-field--text,
    .text-field--checklist,
    .text-field--list {
      padding: 0;
    }
  }

  &__tags {
    .swiper {
      margin: 8px 0;
    }

    .swiper-slide {
      width: unset;
      margin: 0 4px;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    height: 50px;
  }

  &__action-btn {
    width: 50px;
    height: 50px;
    background-color: $color-grey-l80;
    border-radius: 50%;
    justify-content: center;

    svg {
      width: 22px;
      height: 22px;
    }
  }

  &__option {
    color: $color-black;
    display: flex;
    padding: 0 8px;
    align-items: center;
    cursor: pointer;
    gap: 4px;

    button {
      justify-content: center;
      width: 24px;
    }

    &--icon {
      svg {
        width: 24px;
        height: 24px;
      }
    }

    span {
      font-size: 16px;
    }

    &:hover {
      background: $color-grey-l95;
    }
  }

  &__time-left {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    background-color: $color-jade;
    border-radius: 0 0 13px 13px;
    padding: 8px 0;

    span {
      font-size: 21px;
      color: $color-white;
    }
  }
}

@media (max-width: $breakpoint-xxl) {
  .note-card {
    &__title {
      height: 40px;

      span {
        font-size: 18px;
      }
    }

    &__select-btn {
      top: -13px;
      left: -13px;

      svg {
        width: 14px;
        height: 14px;
        padding: 5px;
      }
    }

    &__pin-btn {
      width: 40px;
      height: 40px;

      svg {
        width: 18px;
        height: 18px;
      }
    }

    &__content {
      max-height: 184px;

      .text-input__input {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &__footer {
      height: 40px;
    }

    &__action-btn {
      width: 40px;
      height: 40px;

      svg {
        width: 18px;
        height: 18px;
      }
    }

    &__option {
      button {
        justify-content: center;
        width: 24px;
        height: 36px;
      }
    }

    &__time-left {
      padding: 6px 0;

      span {
        font-size: 18px;
        color: $color-white;
      }
    }
  }
}

@media (max-width: $breakpoint-md) {
  .note-card {
    margin: 12px;

    &__title {
      height: 36px;

      span {
        font-size: 16px;
      }
    }

    &__select-btn {
      top: -11px;
      left: -11px;

      svg {
        width: 13px;
        height: 13px;
        padding: 4px;
      }
    }

    &__pin-btn {
      width: 36px;
      height: 36px;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    &__content {
      margin: 6px 0;
      max-height: 168px;

      .text-input__input {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &__footer {
      height: 32px;
    }

    &__action-btn {
      width: 32px;
      height: 32px;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    &__time-left {
      padding: 4px 0;

      span {
        font-size: 16px;
        color: $color-white;
      }
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .note-card {
    padding: 6px 12px;
    margin: 8px;
    box-shadow: 0 4px 8px $color-box-shadow;

    &__title {
      height: 36px;
    }

    &__select-btn {
      top: -6px;
      left: -6px;

      svg {
        width: 12px;
        height: 12px;
        padding: 3px;
      }
    }

    &__pin-btn {
      width: 32px;
      height: 32px;
      display: flex;

      svg {
        width: 14px;
        height: 14px;
      }
    }

    &__content {
      margin: 6px 0;
      max-height: 152px;
    }

    &__footer {
      height: 32px;
    }

    &__action-btn {
      width: 32px;
      height: 32px;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    &__option {
      color: $color-black;
      display: flex;
      padding: 0 8px;
      align-items: center;
      cursor: pointer;
      gap: 4px;

      button {
        justify-content: center;
        width: 24px;
      }
    }
  }
}
