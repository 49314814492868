@import '../../../../common/sass/variables';

.create-milestone {
  &__model {
    padding: 0 !important;
  }

  &__header {
    padding: 12px 30px;
    font-family: sans-serif;
    font-size: 32px;
    box-shadow: $drop-shadow;
  }

  &__body {
    display: flex;
    justify-content: space-evenly;
    margin: 75px 0;
  }

  &-card {
    $root: &;
    width: 350px;
    height: 450px;
    border: 1px solid $color-gainsboro;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    cursor: pointer;

    &__icon {
      width: 250px;
      height: 250px;
    }

    &__title {
      font-size: 26px;
      font-weight: bold;
      margin-top: 25px;
    }

    &__footer {
      padding: 16px 0;
      font-size: 21px;
    }

    &--standard {
      #{$root}__footer {
        background: $color-alice-blue;
        border-bottom-left-radius: 14px;
        border-bottom-right-radius: 14px;
      }
    }

    &--unique {
      #{$root}__footer {
        background: $color-voracious-white;
        border-bottom-left-radius: 14px;
        border-bottom-right-radius: 14px;
      }
    }

    &:hover {
      opacity: 0.6;
    }
  }

  @media (max-width: $breakpoint-xxl) {
    &__header {
      font-size: 24px;
      padding: 10px 16px;
    }

    &-card {
      width: 300px;
      height: 400px;

      &__icon {
        width: 200px;
        height: 200px;
      }

      &__title {
        font-size: 18px;
        font-weight: bold;
        margin-top: 20px;
      }

      &__footer {
        padding: 16px 0;
        font-size: 16px;
      }
    }

    &__body {
      margin: 60px 0;
    }
  }

  @media (max-width: $breakpoint-md) {
    &__header {
      font-size: 18px;
    }

    &-card {
      width: 250px;
      height: 325px;

      &__icon {
        width: 180px;
        height: 180px;
      }

      &__title {
        font-size: 18px;
        font-weight: bold;
        margin-top: 15px;
      }

      &__footer {
        padding: 16px 0;
        font-size: 16px;
      }
    }

    &__body {
      margin: 40px 0;
    }
  }

  @media (max-width: $breakpoint-sm) {
    &__body {
      flex-direction: column;
      align-items: center;
    }

    &-card {
      width: 200px;
      height: 260px;

      &__icon {
        width: 144px;
        height: 144px;
      }

      &__title {
        font-size: 18px;
        font-weight: bold;
        margin-top: 10px;
      }

      &__footer {
        padding: 10px 0;
        font-size: 16px;
      }
      margin: 10px 0;
    }

    &__body {
      margin: 10px 0;
    }
  }
}
