@import '../.../../../../../common/sass/variables';

.sharing-guide {
  padding: 0;
  border-radius: 30px;
  margin: 8px;

  &__header {
    padding: 20px;
    position: relative;
  }

  &__back-button {
    position: absolute;
    left: 24px;
  }

  &__title {
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 50px;
  }

  &__content {
    padding-bottom: 24px;
    text-align: center;
  }

  &__image {
    width: 100%;
    height: 400px;
    margin: 16px 0;
  }

  &__radio {
    margin: 12px 0;
  }

  &__submit {
    border-radius: 40px;
    padding: 0 40px;
    margin: 16px 0;

    span {
      font-size: 26px;
      font-family: Arial, Helvetica, sans-serif;
    }
  }
}

@media only screen and (max-width: $breakpoint-xxl) {
  .sharing-guide {
    &__header {
      padding: 16px;
    }

    &__back-button {
      left: 20px;
    }

    &__title {
      font-size: 22px;
      line-height: 40px;
    }

    &__content {
      padding-bottom: 20px;
    }

    &__image {
      height: 400px;
      margin: 16px 0;
    }

    &__radio {
      margin: 12px 0;
    }

    &__submit {
      padding: 0 36px;
      margin: 16px 0;

      span {
        font-size: 26px;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-sm) {
  .sharing-guide {
    &__header {
      padding: 16px;
    }

    &__back-button {
      left: 16px;
    }

    &__title {
      font-size: 20px;
      line-height: 40px;
    }

    &__content {
      padding-bottom: 16px;
    }

    &__image {
      height: 250px;
      margin: 16px 0;
    }

    &__radio {
      margin: 12px 0;
    }

    &__submit {
      padding: 0 36px;
      margin: 16px 0;

      span {
        font-size: 20px;
      }
    }
  }
}
