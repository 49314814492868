@import '../../../../../common/sass/variables';

.setting-dropdown {
  .dropdown {
    max-width: unset !important;
    width: 500px;
    border-radius: 20px;
    padding: 30px 20px;
  }

  &__title {
    color: $color-blue-news;
    font-weight: 900;
  }
  &__sub-title {
    color: $color-grey;
    font-size: 16px;
    margin-top: 2px;
  }

  &__categories {
    margin-top: 30px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba($color-grey-l80, 0.6);
  }

  &__categories-header {
    font-size: 16px;
    margin-bottom: 16px;
  }

  &__item {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    border: 0.2px solid $color-grey-l80;
    box-shadow: 0 0 2px $color-box-shadow;
    border-radius: 10px;
    padding: 5px 15px;
    &__toggle {
      width: 36px;
      height: 20px;
      .slider:before {
        height: 16px;
        width: 16px;
      }
    }
    p:last-child {
      flex: 0.15;
      padding-left: 5px;
    }
    p {
      font-size: 16px;
      &:last-child {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        span {
          font-family: $font-family-3;
          color: $color-grey;
          font-size: 14px;
          margin-right: 3px;
        }
      }
    }
  }

  &__all-notify {
    margin-top: 30px;
  }
}

@media (max-width: $breakpoint-xxl) {
  .setting-dropdown {
    .dropdown {
      width: 380px;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .setting-dropdown {
    .dropdown {
      width: calc(100vw - 20px);
    }
  }
}
