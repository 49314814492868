@import '../../common/sass/variables';

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 100px;
  background: $color-white;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  z-index: 99;
  position: fixed;
  width: 100%;

  &__icon-container {
    display: flex;
    align-items: center;
  }

  &__menu-icon {
    width: 44px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__link {
    display: flex;
    a {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: $color-primary;
    }

    &--bold {
      font-weight: bold;
    }
  }

  &__group {
    display: flex;
    align-items: center;
    height: 100px;
  }

  &__logo {
    width: 56px;
    height: 56px;
  }

  &__brand {
    margin-left: 20px;
  }

  &__back {
    span {
      font-weight: bold;
    }

    &:hover {
      color: $color-primary;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__exit-button {
    border: 2px solid $color-primary;
    padding: 28px 40px;
    border-radius: 12px;
    font-family: Arial, Helvetica, sans-serif;
    background-color: $color-white;
    transition: 0.3s;
    gap: 24px;

    span {
      font-size: 24px;
    }

    svg {
      width: 20px;
      height: 20px;

      stroke: $color-black;
    }

    &:hover {
      transition: 0.3s;
      color: $color-white;
      background-color: $color-primary;

      svg {
        stroke: $color-white;
      }
    }
  }

  &__notification-button {
    margin-right: 16px;
    position: relative;
  }

  &__notification-button-active {
    background: $color-bg-noti 0% 0% no-repeat padding-box;
  }

  &__badge {
    background-color: $danger;
    color: white;
    font-size: 13px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    top: -4px;
    left: 30px;
  }

  @media (max-width: $breakpoint-xxl) {
    height: 80px;

    &__group {
      height: 80px;
    }
    &__badge {
      left: 24px;
    }

    &__exit-button {
      padding: 24px 36px;
      gap: 20px;

      span {
        font-size: 20px;
      }

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  @media (max-width: $breakpoint-md) {
    &__logo {
      width: 40px;
      height: 59px;
    }

    height: 65px;
    padding: 10px 20px;

    &__group {
      height: 65px;
    }

    &__exit-button {
      padding: 20px 30px;
      gap: 18px;

      span {
        font-size: 18px;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  @media (max-width: $breakpoint-sm) {
    &__brand {
      display: none;
    }

    &__exit-button {
      padding: 16px 24px;
      gap: 16px;
    }
  }

  &__end {
    margin-top: 100px;

    @media (max-width: $breakpoint-xxl) {
      margin-top: 80px;
    }

    @media (max-width: $breakpoint-md) {
      margin-top: 65px;
    }
  }
}
