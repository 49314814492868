@import '../../../../common/sass/variables';

.manage-sharing {
  padding: 0;
  border-radius: 30px;
  margin: 8px;

  &__header {
    border-bottom: 1px solid $color-grey-l80;
    position: relative;
    padding: 12px;
  }

  &__back-button {
    position: absolute;
    left: 20px;
  }

  &__title {
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 50px;
  }

  &__content {
    padding: 16px 30px;
    min-height: 60vh;

    .ReactVirtualized__Grid__innerScrollContainer {
      overflow: visible !important;
    }

    .empty {
      height: 55vh;
    }
  }
}

.session-item {
  display: flex;
  justify-content: space-between;
  padding: 14px 0;
  align-items: center;
  transition: 0.3s;
  border-bottom: 1px solid $color-grey-l80;

  &:hover {
    background-color: $color-grey-l95;
    transition: 0.3s;
  }

  &__content {
    display: flex;
    flex-direction: column;

    p {
      font-size: 18px;
      font-family: Arial, Helvetica, sans-serif;
      line-height: 24px;
      margin-bottom: 6px;
    }

    span {
      color: $color-grey;
      font-size: 16px;
      font-family: Arial, Helvetica, sans-serif;
      line-height: 22px;
    }
  }

  &__menu {
    width: 160px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color-whitesmoke;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;

    svg {
      transform: rotate(180deg);
    }
  }

  &__role {
    color: $success !important;

    &--editor {
      color: $info !important;
    }
  }

  &__status {
    font-size: 18px;

    &--active {
      color: $success;
    }

    &--expired {
      color: $color-red1;
    }
  }

  &__option {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      background-color: $color-grey-l95;
      transition: 0.3s;
    }

    svg {
      fill: $color-black;
    }
  }
}

@media only screen and (max-width: $breakpoint-xxl) {
  .manage-sharing {
    &__header {
      padding: 8px;
    }

    &__back-button {
      left: 18px;
    }

    &__title {
      font-size: 22px;
      line-height: 40px;
    }

    &__content {
      padding: 12px 26px;
      min-height: 45vh;

      .empty {
        height: 40vh;
      }
    }
  }

  .session-item {
    padding: 12px 0;

    &__content {
      p {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 4px;
      }

      span {
        font-size: 14px;
        line-height: 20px;
      }
    }

    &__menu {
      width: 140px;
      padding: 8px 20px;

      svg {
        transform: rotate(180deg);
      }
    }

    &__status {
      font-size: 16px;
    }

    &__option {
      padding: 8px 16px;
      gap: 10px;

      span {
        font-size: 16px;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-sm) {
  .manage-sharing {
    border-radius: 20px;

    &__header {
      padding: 8px;
    }

    &__back-button {
      left: 12px;
      min-width: 30px;
      height: 30px;
    }

    &__title {
      font-size: 20px;
      line-height: 30px;
    }

    &__content {
      padding: 12px 20px;
    }
  }

  .session-item {
    padding: 12px 0;

    &__content {
      p {
        font-size: 15px;
        line-height: 21px;
      }

      span {
        font-size: 13px;
        line-height: 19px;
      }
    }

    &__menu {
      width: 90px;
      padding: 8px 10px;
    }

    &__status {
      font-size: 14px;
    }

    &__option {
      padding: 6px 14px;
      gap: 10px;

      span {
        font-size: 14px;
      }
    }
  }
}
