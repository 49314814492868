@import '../../common/sass/variables';

.icon {
  width: 16px;
  height: 16px;
  box-sizing: content-box;
  fill: $color-ghost-white;
  pointer-events: none;
  transform: scale(1);
  flex-shrink: 0;

  &--rotate-45 {
    transform: rotate(45deg);
  }
  &--rotate-90 {
    transform: rotate(90deg);
  }
  &--rotate-180 {
    transform: rotate(180deg);
  }
  &--xs {
    width: 12px;
    height: 12px;
  }
  &--sm {
    width: 16px;
    height: 16px;
  }
  &--md {
    width: 20px;
    height: 20px;
  }
  &--lg {
    width: 24px;
    height: 24px;
  }
  &--xl {
    width: 36px;
    height: 36px;
  }
  &--xxl {
    width: 40px;
    height: 40px;
  }

  @media only screen and (max-width: $breakpoint-xxl) {
    &--xs {
      width: 10px;
      height: 10px;
    }
    &--sm {
      width: 14px;
      height: 14px;
    }
    &--md {
      width: 18px;
      height: 18px;
    }
    &--lg {
      width: 20px;
      height: 20px;
    }
    &--xl {
      width: 28px;
      height: 28px;
    }
    &--xxl {
      width: 36px;
      height: 36px;
    }
  }
}
