/* Calibre */
@font-face {
  font-family: 'Calibre';
  src: url('../assets/fonts/CalibreRegular.otf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Calibre';
  src: url('../assets/fonts/CalibreThin.otf') format('truetype');
  font-weight: 100;
}
@font-face {
  font-family: 'Calibre';
  src: url('../assets/fonts/CalibreLight.otf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Calibre';
  src: url('../assets/fonts/CalibreSemibold.otf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Calibre';
  src: url('../assets/fonts/CalibreBold.otf') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Calibre';
  src: url('../assets/fonts/CalibreBlack.otf') format('truetype');
  font-weight: 900;
}

/* Gill Sans */
@font-face {
  font-family: 'Gill Sans';
  src: url('../assets/fonts/GillSans.woff2') format('woff2'), url('../assets/fonts/GillSans.woff') format('woff');
  font-weight: normal;
}
@font-face {
  font-family: 'Gill Sans';
  src: url('../assets/fonts/GillSans-Light.woff2') format('woff2'), url('../assets/fonts/GillSans-Light.woff') format('woff');
  font-weight: 300;
}
@font-face {
  font-family: 'Gill Sans';
  src: url('../assets/fonts/GillSans-Bold.woff2') format('woff2'), url('../assets/fonts/GillSans-Bold.woff') format('woff');
  font-weight: 700;
}

/* Helvetica */
@font-face {
  font-family: 'Helvetica';
  src: url('../assets/fonts/Helvetica-Regular.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Helvetica';
  src: url('../assets/fonts/Helvetica-Bold.ttf') format('truetype');
  font-weight: 700;
}
