@import '../../common/sass/variables';

.note-bin {
  &__title {
    font-size: 32px;
    font-family: $font-family-3;
    margin-bottom: 12px;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  &__checkbox {
    height: 40px;
  }

  &__btn-action {
    display: flex;
    align-items: center;
    gap: 18px;
  }

  &__list {
    padding: 36px 0;
    ::-webkit-scrollbar {
      display: none;
    }
  }
}

@media (max-width: $breakpoint-xxl) {
  .note-bin {
    &__title {
      font-size: 28px;
    }

    &__actions {
      margin-bottom: 12px;
    }

    &__list {
      padding: 28px 0;
    }
  }
}

@media (max-width: $breakpoint-xl) {
  .note-bin {
    &__title {
      font-size: 26px;
    }

    &__list {
      padding: 24px 0;
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .note-bin {
    &__title {
      font-size: 24px;
    }

    &__list {
      padding: 20px 0;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .note-bin {
    &__title {
      font-size: 22px;
    }

    &__list {
      padding: 12px 0;
    }
  }
}
