:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes backInRight {
  0% {
    -webkit-transform: translateX(1000px) scale(0.7);
    transform: translateX(1000px) scale(0.7);
    opacity: 0.7;
  }

  80% {
    -webkit-transform: translateX(0px) scale(0.7);
    transform: translateX(0px) scale(0.7);
    opacity: 0.7;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.animation {
    
  &__back-in-right {
    animation-name: backInRight;
  }

  &__delay-1s {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }

  &__delay-2s {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
  }

  &__delay-3s {
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
  }

  &__delay-4s {
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
  }

  &__delay-5s {
    -webkit-animation-delay: 5s;
    animation-delay: 5s;
  }

  &__delay-6s {
    -webkit-animation-delay: 6s;
    animation-delay: 6s;
  }

  &__delay-7s {
    -webkit-animation-delay: 7s;
    animation-delay: 7s;
  }
}
