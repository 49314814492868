@import '../../common/sass/variables';

.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 16px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background: $color-primary;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $color-primary;
    }

    &:checked + .slider:before {
      -webkit-transform: translateX(16px);
      -ms-transform: translateX(16px);
      transform: translateX(16px);
    }
  }

  .slider {
    &--default {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    &--primary {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $color-primary;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    &:before {
      position: absolute;
      content: '';
      height: 12px;
      width: 12px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }

  .round {
    border-radius: 34px;
    &:before {
      border-radius: 50%;
    }
  }
}
