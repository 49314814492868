@import '../../common/sass/variables';

.empty {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__ellipse {
    width: 400px;
    height: 400px;
    border-radius: 200px;
    background: $color-mint-cream;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    width: 170px;
    height: 170px;
  }

  &__block {
    display: block;
    text-align: center;

    p {
      margin-top: 24px;
    }
  }

  @media (max-width: $breakpoint-xxl) {
    &__ellipse {
      width: 220px;
      height: 220px;
      border-radius: 150px;
    }

    &__icon {
      width: 85px;
      height: 85px;
    }

    &__block {
      p {
        margin-top: 10px;
        font-size: 14px;
      }
    }
  }

  @media (max-width: $breakpoint-md) {
    &__ellipse {
      width: 160px;
      height: 160px;
      border-radius: 100px;
    }

    &__icon {
      width: 60px;
      height: 60px;
    }

    &__block {
      p {
        margin-top: 10px;
        font-size: 10px;
      }
    }
  }
}
