@import '../../common/sass/variables';

.radio-button {
  position: relative;
  cursor: pointer;

  input {
    opacity: 0;
    margin: 0 16px 0 3px;
    visibility: hidden;
  }

  &__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

@media only screen and (max-width: $breakpoint-xxl) {
  .radio-button {
    span {
      font-size: 16px;
    }
    &__checkmark {
      top: 2px;
    }
  }
}
