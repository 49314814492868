@import '../../common/sass/variables';

.classify-menu {
  &__btn {
    padding-left: 4px;
    border-left: 1px solid $color-grey-l80;
  }
}

.classify-list {
  &__container {
    max-width: 250px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__title {
    padding: 0 8px 8px 8px;
    font-size: 18px;
    display: flex;
    align-items: center;
    gap: 6px;
    border-bottom: 0.5px solid $color-grey-l80;

    svg {
      width: 28px;
      height: 28px;
    }
  }

  &__tags {
    padding: 8px;
  }

  &__form {
    position: relative;

    .text-input {
      margin-bottom: 0;
    }

    &-input {
      padding-left: 32px;
      border-radius: 2px;
      border-width: 0.5px;
      height: 45px;
    }

    button {
      position: absolute;
      min-width: unset;
      top: 0;
      left: 0;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  &__tag-list {
    padding-top: 6px;
    height: 140px;
    ::-webkit-scrollbar {
      display: none;
    }
  }

  &__button-group {
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
  }

  &__button-clear,
  &__button-apply {
    min-width: 87px;
    height: 35px;
    border-radius: 5px;
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__button-clear {
    background-color: $color-grey-l80;
    color: $color-black;
    border: none;
  }

  &__actions {
    border-top: 0.5px solid $color-grey-l80;
    padding: 8px 8px 0 8px;
    display: flex;
    color: $color-grey;
    font-size: 18px;
    cursor: pointer;

    &-icon {
      border-radius: 2px;
      display: flex;
      border: 1px solid $color-grey;
      padding: 0;
      margin-right: 8px;

      svg {
        width: 22px;
        height: 22px;
        stroke: $color-grey;
        stroke-width: 1.5px;
      }
    }

    &:hover {
      color: $color-black;

      .classify-menu__actions-icon {
        border: 1px solid $color-black;

        svg {
          stroke: $color-black;
        }
      }
    }
  }
}

.tag-option {
  margin: 6px 0;

  .checkbox {
    padding-top: 3px;
    padding-left: 24px;
    min-height: unset;

    &__title {
      font-size: 18px;
    }

    &__checkmark {
      top: 8px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

@media (max-width: $breakpoint-xxl) {
  .classify-list {
    &__title {
      font-size: 16px;
      gap: 4px;

      svg {
        width: 26px;
        height: 26px;
      }
    }

    &__tags {
      padding: 8px;
    }

    &__form {
      &-input {
        padding-left: 28px;
        height: 40px;
      }

      button {
        top: 1px;
        left: 8px;

        svg {
          width: 14px;
          height: 14px;
        }
      }
    }

    &__actions {
      font-size: 16px;

      &-icon {
        margin-right: 6px;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .tag-option {
    margin: 4px 0;

    .checkbox {
      &__title {
        font-size: 16px;
      }

      &__checkmark {
        top: 3px;

        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-xl) {
  .classify-list {
    &__title {
      font-size: 14px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    &__form {
      &-input {
        height: 36px;
      }

      button {
        top: -1px;
      }
    }

    &__actions {
      font-size: 14px;

      &-icon {
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  .tag-option {
    .checkbox {
      &__title {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .classify-list {
    &__form {
      &-input {
        height: 32px;
        font-size: 14px;
      }

      button {
        top: -3px;
      }
    }
  }
}
