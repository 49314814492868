@import '../../common/sass/variables';

@keyframes spinner {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.loading-spinner {
  display: inline-block;

  &__content {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;

    &__spinner {
      position: absolute;

      border: 2px solid $color-primary-d15;
      border-top-color: transparent;
      border-radius: 50%;
      box-sizing: content-box;
      animation: spinner 1s linear infinite;

      &--xs {
        width: 16px;
        height: 16px;
      }

      &--sm {
        width: 24px;
        height: 24px;
      }

      &--md {
        width: 36px;
        height: 36px;
        border-width: 3px;
      }

      &--lg {
        width: 48px;
        height: 48px;
        border-width: 4px;
      }
    }
  }
}
