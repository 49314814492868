@import '../../common/sass/variables';

.text-input__input {
  $root: &;
  font-size: 18px;
  line-height: 19px;
  width: 100%;
  height: 50px;
  color: $color-black;
  border: 1px solid $color-grey-l20;
  background-color: transparent;
  border-radius: 10px;
  padding: 0 17px;
  font-family: $font-family-2;
  font-weight: normal;

  &:hover,
  &:focus {
    border-color: $color-primary;
  }

  &::-ms-clear {
    display: none;
  }
  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    transition: opacity ease-in-out 0.25s;
  }

  &[disabled] {
    background: $color-grey-l95;
  }

  &--has-error {
    border-color: $color-red;
    color: $color-red;
  }
  &--has-label {
    margin-top: var(--sp-2);
  }

  &--textarea {
    padding: var(--sp-3);
    resize: none;
    font-family: $font-family-3;
  }

  &--file {
    border: none;
    padding: unset;
    border-radius: unset;
    font-size: 24px;
  }
}

.text-input {
  margin-bottom: 28px;
  position: relative;

  &__error {
    position: absolute;
    top: -22px;
    left: 18px;
    color: $color-red;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 95%;
    overflow: hidden;

    &--bottom {
      top: unset;
      bottom: -22px;
      left: 0px;
    }
  }

  &__label {
    color: $color-black;
    font-size: 24px;

    &--required::after {
      content: '*';
      color: $danger;
    }
  }

  &__end-adornment {
    position: absolute;
    right: 14px;
    top: 1px;
  }

  &--filled {
    .text-input__input {
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: unset;
      padding: unset;
      border-width: 2px;
    }
  }

  ::-webkit-file-upload-button {
    background: $color-white;
    height: 50px;
    border: 1px solid $color-gainsboro;
    border-radius: 4px;
    font-size: 24px;
    padding: 0 15px;
    margin-right: 10px;

    &:hover,
    &:focus {
      border-color: $color-primary;
    }
  }

  input[type='file'] {
    color: $color-grey;
  }
}

@media only screen and (max-width: $breakpoint-xxl) {
  .text-input {
    margin-bottom: 20px;

    &__error {
      font-size: 16px;
    }

    &__label {
      color: $color-black;
      font-size: 18px;
    }

    ::-webkit-file-upload-button {
      height: 40px;
      border: 1px solid $color-gainsboro;
      border-radius: 4px;
      font-family: $font-family-2;
      font-size: 18px;
      padding: 0 10px;
    }
  }

  .text-input__input {
    font-size: 16px;
    border-radius: 8px;
    height: 40px;

    &--file {
      border: none;
      padding: unset;
      border-radius: unset;
      font-size: 18px;
    }
  }
}
