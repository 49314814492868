@import '../../../../common/sass/variables';

.noti {
  // > 1600
  &__container {
    font-family: $font-family-3;
    position: fixed;
    z-index: 99;
    margin-top: 3px;
    width: 525px;
    max-height: 100vh;
    right: 0;
    top: 100px;
    bottom: 0;
    box-shadow: 0 0 18px $color-box-shadow;
    background-color: $color-white;
    border-radius: 20px 0px 0px 0px;
    p {
      font-family: inherit;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    color: $color-blue-news;
    align-items: center;
    padding: 24px;
    border-bottom: 1px solid $color-grey-l80;
  }

  &__title {
    font-size: 24px;
    font-weight: 900;
    color: $color-primary-d15;
  }

  &__setting {
    font-size: 16px;
    color: $color-primary-d15;
    cursor: pointer;
  }

  &__wrap-button {
    padding: 25px;

    &__button {
      width: 100%;
      height: unset;
      border-width: 1px;
      padding: 8px 0;

      span {
        font-family: Arial, Helvetica, sans-serif;
        color: $color-blue-news;
        font-weight: bold;
      }

      &:hover {
        border-width: 1px;
      }
    }
  }

  &__content {
    overflow: auto;
    display: block;
    width: 100%;
    position: absolute;
    bottom: 49px;
    z-index: -1;
    top: 160px;
    overscroll-behavior: contain;

    ::-webkit-scrollbar {
      display: none;
    }

    &--loading {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__scroll {
      width: 100%;
    }
    &__empty {
      text-align: center;
      flex: 1;
      color: $color-grey-l20;
    }
  }

  &__see-all {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    background-color: $color-white;
    color: $color-blue-news;
    border-top: 1px solid $color-grey-l80;
    font-weight: bold;
    cursor: pointer;
    padding: 18px;
    font-size: 18px;
  }

  &__layout {
    width: 100vw;
    position: fixed;
    height: 100vh;
    z-index: 9;
  }

  &__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 98;
  }
}

@media (max-width: $breakpoint-xxl) {
  .noti {
    &__container {
      width: 400px;
      top: 80px;
    }

    &__header {
      padding: 20px;
    }

    &__title {
      font-size: 20px;
    }

    &__setting {
      font-size: 16px;
    }

    &__wrap-button {
      padding: 20px;

      &__button {
        padding: 8px 0;

        span {
          font-family: Arial, Helvetica, sans-serif;
          color: $color-blue-news;
          font-weight: bold;
          font-size: 16px;
        }
      }
    }

    &__content {
      top: 140px;
    }

    &__see-all {
      padding: 16px;
      font-size: 16px;
    }
  }
}

@media (max-width: $breakpoint-md) {
  .noti {
    &__container {
      top: 65px;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .noti {
    &__container {
      width: 100vw;
    }
  }
}
