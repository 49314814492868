@import '../../../../common/sass/variables';

.sharing-dropdown {
  &__content {
    display: flex;
    flex-direction: column;
  }

  &__option {
    padding: 12px 20px;
    display: flex;
    gap: 12px;
    align-items: center;
    cursor: pointer;
  }
}

@media (max-width: $breakpoint-xxl) {
  .sharing-dropdown {
    &__option {
      padding: 8px 16px;
      gap: 10px;

      span {
        font-size: 16px;
      }
    }
  }
}
