@import '../../../../../common/sass/variables';

.noti-item {
  &__container {
    width: 100%;
    display: flex;
    padding: 16px 10px;
    margin-bottom: 2px;
    cursor: pointer;
  }

  &--seen {
    background: $bg-noti 0% 0% no-repeat padding-box;
  }

  &__icon {
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon-wrapper {
    border-radius: 50%;
    box-shadow: 0.25px 0.25px 2.5px rgba(0, 0, 0, 0.3);
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 60%;
      height: 60%;
    }
  }

  &__content {
    margin-right: auto;

    &__header {
      font-size: 21px;
      color: $color-black-noti;
      margin-bottom: 12px;
    }

    &__para {
      margin-bottom: 13px;
    }

    &__des {
      font-size: 18px;
      font-family: $font-family-3;
    }

    &__date {
      font-size: 16px;
      font-family: $font-family-3;
    }
  }

  &__threeDot {
    margin-right: 10px;
  }

  &__options {
    padding: 5px 8px 5px 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__option-item {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__option-label {
    margin-left: 8px;
    font-size: 16px;
    font-family: $font-family-2;
  }
}

@media (max-width: $breakpoint-xxl) {
  .noti-item {
    &__icon-wrapper {
      width: 50px;
      height: 50px;
    }

    &__content {
      &__header {
        font-size: 18px;
        margin-bottom: 10px;
      }

      &__para {
        margin-bottom: 10px;
      }

      &__des {
        font-size: 16px;
      }

      &__date {
        font-size: 14px;
      }
    }
  }
}
