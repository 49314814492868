@import './common/sass/variables';
@import './common/sass/common';
@import './common/sass/font.scss';
@import '~react-toastify/dist/ReactToastify.css';
@import './common/sass/animation';
@import 'swiper/css';
@import 'swiper/css/navigation';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  height: 100vh;
  height: -webkit-fill-available;
  margin: 0;
  background-color: $color-ghost-white;
  font-family: $font-family-2;
  color: $color-black;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  display: block;
  width: 6px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $color-grey-l20;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $color-primary;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $color-primary-d15;
}

button,
input {
  border: none;
  font-size: 18px;
}

p,
span {
  font-family: $font-family-2;
  font-size: 18px;
  line-height: 21px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

textarea:focus,
input:focus {
  outline: none;
}

#root {
  display: flex;
  position: relative;
  min-height: 100%;
  min-height: -webkit-fill-available;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.sidebar--collapsed .container {
  width: calc(100% - 64px);
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
}

.d-flex--center {
  display: flex;
  align-items: center;
  justify-content: center;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

.text-bold {
  font-weight: bold;
}

.root-features {
  margin-left: 120px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  height: -webkit-fill-available;
  background: $bg-primary;

  &--bar-hidden {
    margin-left: 0 !important;
  }

  @media (max-width: $breakpoint-xxl) {
    margin-left: 100px;
  }

  @media (max-width: $breakpoint-xl) {
    margin-left: 90px;
  }

  @media (max-width: $breakpoint-sm) {
    margin-left: 0;
  }
}

.custom-tooltip {
  background-color: $bg-tooltip-yellow !important;
  font-size: 21px !important;
  padding: 4px 16px !important;
  color: $color-black !important;
  max-width: 450px;
  z-index: 10001 !important;

  &--event {
    pointer-events: auto !important;
  }
}

@media (max-width: $breakpoint-xxl) {
  .custom-tooltip {
    font-size: 18px !important;
    padding: 6px 10px !important;
  }
}

@media (max-width: $breakpoint-xl) {
  .custom-tooltip {
    font-size: 16px !important;
    padding: 4px 8px !important;
  }
}

@media (max-width: $breakpoint-sm) {
  .custom-tooltip {
    padding: 2px 6px !important;
  }
}
