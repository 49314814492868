@import '../../common/sass/variables';

.ReactModal__Overlay {
  opacity: 0;
  transition: all 200ms ease-in-out;
  .dialog--fixed-right {
    transform: translateX(100%);
    transition: all 200ms ease-in-out;
  }
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  .dialog--fixed-right {
    transform: translateX(0);
  }
}

.ReactModal__Overlay--before-close {
  opacity: 0;

  .dialog--fixed-right {
    transform: translateX(100%);
  }
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

.dialog {
  position: relative;
  padding: var(--sp-8);
  width: 600px;
  background-color: $color-white;
  border-radius: 4px;
  box-shadow: $drop-shadow;
  z-index: 1002 !important;
  overflow: auto;

  &:focus-visible {
    outline: none;
  }

  &--xxl {
    width: 980px;
  }
  &--xl {
    width: 800px;
  }
  &--lg {
    width: 700px;
  }
  &--sm {
    width: 500px;
  }
  &--xs {
    width: 400px;
  }
  &--fixed-right {
    overflow: auto;
    max-height: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
    width: 100%;
    max-width: 470px;
    border-radius: 0;
    @media (max-width: $breakpoint-md) {
      padding: var(--sp-7) var(--sp-7) var(--sp-11);
    }
  }
  &__close {
    position: absolute;
    top: 6px;
    right: 30px;
    transition: all 0.2s ease-in;

    svg {
      fill: $color-black;
    }
  }
  &__footer {
    position: relative;
    padding-top: 18px;
    margin-top: 26px;
    &:before {
      content: '';
      position: absolute;
      left: -30px;
      right: -30px;
      top: 0;
      height: 1px;
    }
    > button {
      width: 100%;
    }
  }
  &__title {
    font-size: 18px;
    letter-spacing: inherit;
  }

  @media only screen and (max-width: $breakpoint-xxl) {
    padding: 18px;

    &--xxl {
      width: 800px;
    }
    &--xl {
      width: 700px;
    }
    &--lg {
      width: 600px;
    }
    &--sm {
      width: 400px;
    }
    &--xs {
      width: 300px;
    }

    &__close {
      top: 4px;
      right: 16px;
    }
  }

  @media only screen and (max-width: $breakpoint-md) {
    &__close {
      top: 0;
      right: 16px;
    }
  }
}

.dialog-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  position: fixed;
  background: rgba(37, 43, 64, 0.6);
  left: 0;
  right: 0;
  height: 100%;
  min-height: 100vh;
  top: 0;
  z-index: 10000;
  &--fixed-right {
    overflow: inherit;
  }
}
