@import '../../../../common/sass/variables';

.news {
  &__container {
    font-family: $font-family-3;
    position: fixed;
    z-index: 99;
    margin-top: 3px;
    width: 525px;
    max-height: 100vh;
    right: 0;
    top: 100px;
    bottom: 0;
    box-shadow: 0 0 18px $color-box-shadow;
    background-color: $color-white;
    border-radius: 20px 0px 0px 0px;
    padding: 0px 10px 5px 10px;
    p {
      font-family: inherit;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    color: $color-blue-news;
    height: 72px;
    align-items: center;
  }

  &__title {
    font-size: 24px;
    font-weight: 900;
  }

  &__settings {
    font-size: 16px;
  }

  &__content {
    overflow: auto;
    padding-top: 26px;
    width: 98%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 0;
    z-index: -1;
    top: 80px;
    padding-right: 10px;
    overscroll-behavior: contain;
    &::-webkit-scrollbar {
      display: none;
    }
    &__empty {
      text-align: center;
      flex: 1;
      color: $color-grey-l20;
    }
  }

  &__layout {
    width: 100vw;
    position: fixed;
    height: 100vh;
    z-index: 9;
  }

  &__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 98;
  }
}

@media (max-width: $breakpoint-xxl) {
  .news {
    &__container {
      top: 80px;
      width: 400px;
    }
    &__content {
      padding-top: 8px;
      top: 60px;
    }
    &__header {
      height: 60px;
    }
    &__title {
      font-size: 20px;
    }
    &__settings {
      font-size: 12px;
    }
  }
}

@media (max-width: $breakpoint-md) {
  .news {
    &__container {
      top: 65px;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .news {
    &__container {
      padding: 0px 10px 5px 10px;
      top: 65px;
      width: 100vw;
      p {
        line-height: 16px;
      }
    }
  }
}
