@import '../../../../common/sass/variables';

.fw-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 13px 60px;
  position: relative;

  &--left {
    display: flex;
    align-items: center;
  }

  &__name {
    font-size: 24px;
    font-weight: bold;
  }

  &__age {
    color: $color-grey;
  }

  &__back-button {
    margin-right: 9px;
  }

  &__avatar {
    margin-right: 15px;
  }

  &__back-title {
    font-size: 32px;
    color: $info;
    font-weight: bold;
    height: 62px;
    justify-content: center;
    align-items: center;
    display: flex;

    &--unique {
      color: $color-forbidden-fruit;
    }
  }

  &--view {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    svg {
      width: 80px;
      height: 80px;
    }
  }

  @media (max-width: $breakpoint-xxl) {
    margin: 10px 40px;

    &__back-title {
      font-size: 24px;
      height: 62px;
      justify-content: center;
      align-items: center;
      display: flex;
    }

    &__name {
      font-size: 18px;
    }

    &--view {
      svg {
        width: 72px;
        height: 72px;
      }
    }
  }

  @media (max-width: $breakpoint-xl) {
    &__search {
      width: 500px;
    }

    &--view {
      svg {
        width: 60px;
        height: 60px;
      }
    }
  }

  @media (max-width: $breakpoint-lg) {
    &__search {
      width: 400px;
    }
  }

  @media (max-width: $breakpoint-md) {
    margin: 0 10px;
    margin-top: 10px;

    display: block;

    &__search {
      float: left;
      width: calc(100% - 65px);
      margin: 10px 0;
    }

    &--right {
      float: right;
    }

    &__back-title {
      font-size: 22px;
      height: 62px;
      justify-content: center;
      align-items: center;
      display: flex;
    }
  }

  @media (max-width: $breakpoint-sm) {
    &__search {
      width: calc(100%);
    }

    &__back-title {
      font-size: 22px;
      height: 62px;
      justify-content: center;
      align-items: center;
      display: flex;
    }

    &--right {
      position: absolute;
      bottom: 65px;
      right: 0px;
    }

    &--view {
      svg {
        width: 48px;
        height: 48px;
      }
    }
  }
}
