@import '../../common/sass/variables';

.dropdown {
  padding: var(--sp-3) 0;
  font-size: 14px;
  background: $color-white;
  width: max-content;
  color: $color-black;
  box-shadow: $drop-shadow;
  border-radius: 4px;

  &-elm {
    cursor: pointer;
  }

  &__item {
    padding: var(--sp-4) var(--sp-6);

    &:hover {
      background-color: $color-grey-l80;
    }
  }
}
