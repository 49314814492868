@import '../../common/sass/variables';

.tag {
  padding: 8px 20px;
  max-width: 180px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: fit-content;

  .icon {
    position: relative;
    left: 6px;
  }

  &__name {
    color: $color-black;
    font-size: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:before {
      content: '•';
      font-size: 24px;
      position: relative;
      top: 3px;
      margin-right: 4px;
    }

    &--empty {
      font-style: italic;
      color: $color-sienna;

      &:before {
        content: '';
      }
    }
  }

  &--groceries {
    background-color: $color-egg-white;
  }

  &--medication {
    background-color: $color-albe-white;
  }

  &--journal {
    background-color: $color-pale-blue;
  }

  &--custom {
    background-color: $color-grey-l80;
  }

  &--empty {
    background-color: $color-grey-l95;
    cursor: unset;
  }
}

@media (max-width: $breakpoint-xxl) {
  .tag {
    padding: 4px 16px;
    max-width: 160px;

    &__name {
      font-size: 16px;

      &:before {
        font-size: 20px;
      }
    }
  }
}
