@import '../../../../../common/sass/variables';

.news-card {
  width: 100%;
  cursor: pointer;
  position: relative;
  width: calc(100% - 6px);
  height: 300px;
  margin-bottom: 25px;
  border-radius: 24px;
  box-shadow: 0 0 6px $color-box-shadow;
  padding: 15px;

  &--pink {
    background: linear-gradient(122deg, $color-white 0%, $color-news-card-1 100%);
  }

  &--purple {
    background: linear-gradient(122deg, $color-white 0%, $color-news-card-2 100%);
  }

  &--blue {
    background: linear-gradient(122deg, $color-white 0%, $color-news-card-3 100%);
  }
  &__header {
    display: flex;
    justify-content: center;
    .col {
      width: 50%;
      display: flex;
      flex-direction: column;
    }
  }
  &__img {
    align-items: center;
    justify-content: space-around;
    img {
      border-radius: 10px;
      height: 100px;
      object-fit: cover;
      max-width: 100%;
      max-height: 100%;
    }
  }
  &__type {
    font-size: 13px;
    color: $color-primary;
    font-weight: 900;
  }

  &__title {
    font-size: 24px;
    line-height: 26px;
    flex: 1;
    display: flex;
    align-items: center;
    font-weight: 900;
  }

  &:hover {
    .news-card__title {
      color: $color-blue-news;
      text-decoration: underline;
    }
  }

  &__content {
    margin-top: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
    h3,
    h2,
    h1,
    p {
      font-size: 14px;
    }
    img {
      display: none;
    }
  }

  &__footer {
    margin-top: 26px;
    p {
      color: $color-grey;
      font-size: 14px;
    }
  }

  &__see-more {
    position: absolute;
    bottom: 15px;
    right: 15px;
    background-color: $color-white;
    width: 145px;
    height: 40px;
    border-radius: 25px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
}

@media (max-width: $breakpoint-xxl) {
  .news-card {
    height: 250px;
    margin-bottom: 20px;
    &__type {
      font-size: 10px;
    }
    &__title {
      font-size: 18px;
      line-height: 20px;
    }
    &__content {
      margin-top: 12px;
      font-size: 14px;
    }
    &__footer {
      margin-top: 12px;
      p {
        font-size: 10px;
        line-height: 12px;
      }
    }
    &__img {
      img {
        width: 85%;
      }
    }
    &__see-more {
      bottom: 10px;
      right: 10px;
      width: 110px;
      height: 30px;
      p {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .news-card {
    height: 300px;
    &__img {
      align-items: flex-end;
    }
    &__footer {
      width: 60%;
    }
    &__see-more {
      width: 84px;
    }
  }
}
