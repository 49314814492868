@import '../../../../common/sass/variables';

.tabs {
  &__container {
    width: calc(100vw - 120px);
    justify-content: center;
    clear: both;
  }

  &__wrapper {
    position: relative;
  }

  &__left-arrow {
    position: absolute;
    top: 0;
    left: 12px;
    height: 100%;
    opacity: 0.6;
    z-index: 1;
    transition: 0.3s;
    transform: scaleY(1.3);
  }

  &__right-arrow {
    position: absolute;
    top: 0;
    right: 16px;
    height: 100%;
    opacity: 0.5;
    z-index: 1;
    transition: 0.3s;
    transform: rotate(180deg) scaleY(1.3);
  }

  &__disabled-arrow {
    opacity: 0;
    transition: 0.3s;
  }

  &__item {
    &:first-child {
      margin-left: 60px;
    }
    &:last-child {
      margin-right: 60px;
    }
  }

  &__button {
    border-radius: 10px;
    margin: 0 4px;
    width: 230px;
    height: 65px;
    justify-content: start;

    svg {
      margin: 0 12px;
    }

    &:hover {
      border-color: $color-dark-orange;
      border-width: 3px;
    }

    &--activated {
      font-weight: 700;
      border-color: $color-dark-orange;
      border-width: 3px;
    }
  }

  @media (max-width: $breakpoint-xxl) {
    &__container {
      width: calc(100vw - 90px);
    }

    &__button {
      width: 210px;
      height: 55px;
    }

    &__item {
      &:first-child {
        margin-left: 40px;
      }
      &:last-child {
        margin-right: 40px;
      }
    }
  }

  @media (max-width: $breakpoint-md) {
    &__item {
      &:first-child {
        margin-left: 20px;
      }
      &:last-child {
        margin-right: 20px;
      }
    }
  }

  @media (max-width: $breakpoint-sm) {
    &__container {
      width: 100vw;
    }

    &__button {
      flex-direction: column;
      width: 160px;
      height: 64px;
      justify-content: center;

      span {
        font-size: 15px;
      }
    }
  }
}
