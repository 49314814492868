@import '../../common/sass/variables';

.checkbox {
  $root: &;
  display: inline-block;
  position: relative;
  padding-left: 44px;
  padding-top: 4px;
  margin-bottom: 0;
  max-width: inherit;
  line-height: 1.3;
  min-height: 34px;
  cursor: pointer;

  &--has-focus {
    .using-kb & {
      outline: 1px dashed var(--color-highlight);
      outline-offset: 3px;
    }
  }
  &--no-label {
    padding-left: 16px;
  }
  &--small {
    padding-left: 18px;
  }

  &__input {
    position: absolute;
    height: 0 !important;
    opacity: 0;
    pointer-events: none;
  }

  &__title {
    color: var(--color-text);
    font-size: 18px;
    margin-top: 5px;
    margin-left: 2px;
    display: inline-block;
    position: relative;
    line-height: 1.2;
    margin-bottom: 0;

    @media only screen and (max-width: $breakpoint-sm) {
      font-size: 14px;
    }

    &--muted {
      color: #ccc;
    }

    #{$root}__input[disabled] ~ & {
      color: var(--color-placeholder);
    }
  }

  &__checkmark {
    border-radius: 6px;

    svg {
      width: 34px;
      height: 34px;
    }

    position: absolute;
    left: 0;
    top: 1px;
    transition: all 0.2s ease-in-out;
    color: var(--color-highlight);
  }
}

@media only screen and (max-width: $breakpoint-xxl) {
  .checkbox {
    padding-left: 32px;

    &__checkmark {
      left: 0;
      top: 4px;
      svg {
        width: 24px;
        height: 24px;
      }
    }

    &__title {
      font-size: 14px;
      margin-top: 0;
    }
  }
}
