@import '../../common/sass/variables';

.file-name {
  &__container {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__label {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__title {
    font-size: 21px;
    line-height: 30px;
    font-weight: 600;
    max-width: 75%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__sub-title {
    font-size: 18px;
    font-weight: 400;
  }
}

@media (max-width: $breakpoint-xxl) {
  .file-name {
    &__title {
      font-size: 18px;
      line-height: 26px;
    }

    &__sub-title {
      font-size: 14px;
    }
  }
}

@media (max-width: $breakpoint-xl) {
  .file-name {
    &__title {
      font-size: 14px;
      line-height: 20px;
    }

    &__sub-title {
      font-size: 12px;
    }
  }
}

@media (max-width: $breakpoint-md) {
  .file-name {
    &__container {
      gap: 8px;
    }

    &__label {
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-direction: column;
    }

    &__title {
      font-size: 14px;
      max-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &__sub-title {
      font-size: 11px;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .file-name {
    &__label {
      width: calc(100% - 36px);
    }
  }
}
