@import '../.../../../../../common/sass/variables';

.session-detail {
  padding: 0;
  border-radius: 30px;
  margin: 8px;

  &__header {
    position: relative;
    padding: 12px;
  }

  &__title {
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 40px;
  }

  &__content {
    padding-bottom: 24px;
  }

  &__section {
    border-top: 1px solid $color-grey-l80;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__label {
    color: $color-primary-d15;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
  }

  &__info {
    padding: 0 20px;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
  }

  &__babies {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: 25vh;
    overflow: auto;
  }

  &__baby {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__name {
    display: flex;
    flex-direction: column;
    gap: 6px;

    p {
      font-weight: bold;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 24px;
    }

    span {
      color: $color-grey;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 18px;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    padding: 16px 24px 0;
  }
}

@media only screen and (max-width: $breakpoint-xxl) {
  .session-detail {
    &__header {
      padding: 10px;
    }

    &__title {
      font-size: 22px;
      line-height: 35px;
    }

    &__content {
      padding-bottom: 20px;
    }

    &__section {
      padding: 12px;
      gap: 8px;
    }

    &__label {
      font-size: 16px;
    }

    &__info {
      padding: 0 20px;
      font-size: 16px;
    }

    &__babies {
      gap: 12px;
    }

    &__baby {
      gap: 10px;
    }

    &__name {
      gap: 2px;

      p {
        font-size: 20px;
      }

      span {
        font-size: 16px;
      }
    }

    &__actions {
      padding: 12px 20px 0;
    }
  }
}

@media only screen and (max-width: $breakpoint-sm) {
  .session-detail {
    &__header {
      padding: 8px;
    }

    &__title {
      font-size: 20px;
      line-height: 30px;
    }

    &__content {
      padding-bottom: 16px;
    }

    &__section {
      padding: 10px;
      gap: 6px;
    }

    &__label {
      font-size: 15px;
    }

    &__info {
      padding: 0 20px;
      font-size: 15px;
    }

    &__babies {
      gap: 10px;
    }

    &__baby {
      gap: 10px;
    }

    &__name {
      gap: 0;

      p {
        font-size: 18px;
      }

      span {
        font-size: 15px;
      }
    }

    &__actions {
      padding: 6px 16px 0;
    }
  }
}
