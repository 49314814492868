@import '../../common/sass/variables';

.search-form {
  position: relative;

  button {
    position: absolute;
    width: 42px;
    min-width: unset;
    z-index: 1;
    top: 0;

    .button__content {
      display: flex;
      justify-content: center;
    }
  }

  .text-input {
    margin-bottom: 0;
  }

  .input-search {
    padding: 0 17px 0 41px;
    border-radius: 25px;
    height: 50px;
    width: 600px;
  }

  .search-clear {
    right: -50px;
  }

  @media (max-width: $breakpoint-xxl) {
    .input-search {
      height: 40px;
    }
  }

  @media (max-width: $breakpoint-md) {
    .input-search {
      width: 100%;
    }
  }
}
