@import '../../../../common/sass/variables';

.sidebar-item {
  $root: &;

  position: relative;
  height: 70px;
  width: 70px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: flex-start;
  border-radius: 50%;
  padding: 5px;
  margin: 20px auto;
  transition: 0.5s;

  &:hover {
    background-color: $color-primary-l20;
    transition: 0.3s;
  }

  &--full {
    width: 86%;
    border-radius: 50px;
    transition: width ease-in-out 0.25s;
  }

  &--active {
    background: $color-white !important;
  }

  &__image {
    height: 100%;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__text {
    width: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    height: 100%;
    justify-content: space-around;

    &--title {
      color: $color-black;
      font-size: 16px;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 24px;
      line-height: 24px;
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }

    &--sub-title {
      color: $color-grey;
      font-size: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@media only screen and (max-width: $breakpoint-xxl) {
  .sidebar-item {
    margin: 16px auto;

    &--full {
      width: 90%;
      border-radius: 50px;
      transition: width ease-in-out 0.25s;
    }

    &__text {
      margin-left: 16px;

      &--title {
        font-size: 22px;
        line-height: 22px;
      }

      &--sub-title {
        font-size: 16px;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-xl) {
  .sidebar-item {
    height: 60px;
    width: 60px;
    padding: 4px;
    margin: 16px auto;

    &--full {
      width: 90%;
    }

    &__text {
      width: calc(100% - 70px);
      margin-left: 14px;

      &--title {
        font-size: 18px;
        line-height: 18px;
      }

      &--sub-title {
        font-size: 14px;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-sm) {
  .sidebar-item {
    height: 52px;
    width: 52px;
    padding: 4px;
    margin: 12px auto;

    &--full {
      width: 90%;
    }

    &__text {
      width: calc(100% - 60px);

      &--title {
        font-size: 16px;
        line-height: 16px;
      }

      &--sub-title {
        font-size: 12px;
      }
    }

    &__image {
      img {
        width: 44px;
      }
    }
  }
}
