@import '../../../../common/sass/variables';

.user-dropdown {
  $root: &;

  position: relative;
  display: contents;

  &__active {
    #{$root}__dropbtn {
      border: 1px solid $color-primary-d15;
    }
    #{$root}__btn-icon {
      transform: rotate(180deg);
    }
  }

  &__img {
    cursor: pointer;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin: 0 5px;
    object-fit: cover;
  }

  &__dropbtn {
    display: flex;
    align-items: center;
    height: 60px;
    border-radius: 30px;
    border: 1px solid #bdbdbd;
    min-width: 300px;
    cursor: pointer;
  }

  &__username {
    flex: 1;
    margin-left: 5px;
    font-family: $font-family-3;
    font-size: 18px;
  }

  &__content {
    width: 300px;
    background: #ffffff;
    border-radius: 4px;

    a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      font-size: 18px;
      font-family: $font-family-3;

      &:hover {
        background-color: $color-anti-flash-white;
      }
    }
  }

  &__btn-icon {
    margin: 0 15px;
    transition-duration: 0.3s;
    transition-property: transform;
  }

  &__email {
    border-bottom: 1px solid #f1f2f5;
  }

  @media (max-width: $breakpoint-md) {
    &__dropbtn {
      min-width: 200px;
      height: 50px;
      border-radius: 25px;
    }

    &__content {
      width: 200px;

      a {
        font-size: 16px;
      }
    }

    &__img {
      height: 40px;
      width: 40px;
    }
  }

  @media (max-width: $breakpoint-sm) {
    &__username,
    &__btn-icon {
      display: none;
    }
    &__dropbtn {
      min-width: unset;
    }
  }
}
