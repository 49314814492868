@import '../../common/sass/variables';

.rich-text {
  .text-input {
    margin-bottom: 0;
    width: 100%;

    &__input--textarea {
      padding: 0;
    }
  }

  &__input {
    border: unset;
    font-size: 21px;
    min-height: 45px;
    width: 100%;

    &-text {
      padding: 0 16px;
      line-height: 28px;
      height: unset !important;
    }
  }
}

.add-button {
  color: $color-grey;
  display: flex;
  gap: 6px;
  height: 45px;
  align-items: center;
  border-top-width: 0.5px;
  border-bottom-width: 0.5px;
  border-color: $color-grey-l80;
  border-style: solid;
  border-left: none;
  border-right: none;
  padding: 0 16px;
  cursor: pointer;

  svg {
    stroke: $color-grey;
    stroke-width: 2px;
  }
}

.text-field {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 6px;

  .text-input {
    margin-bottom: 0;
    width: 100%;

    &__input--textarea {
      padding: 0;
    }
  }

  &__input {
    border: none;
    font-size: 21px;
    line-height: 40px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

    &--complete {
      text-decoration: line-through;
      color: $color-grey;
    }
  }

  &--checklist {
    padding: 0 16px;
    height: 45px;
    gap: 0;

    .checkbox {
      padding-left: 24px;
    }

    .icon {
      width: 16px;
    }

    .text-field__input {
      padding: 0;
    }
  }

  &--list {
    padding: 0 16px;
    height: 45px;

    .text-field__input {
      padding: 0;
    }
  }

  &--list-focus {
    border-top-width: 0.5px;
    border-bottom-width: 0.5px;
    border-color: $color-grey-l80;
    border-style: solid;
    border-left: none;
    border-right: none;
  }

  &--text {
    padding: 0 16px;
  }

  &__icon {
    &--close {
      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
}

@media (max-width: $breakpoint-xxl) {
  .rich-text {
    &__input {
      font-size: 18px;
      min-height: 40px;

      &-text {
        padding: 0 16px;
        line-height: 24px;
      }
    }
  }

  .add-button {
    height: 40px;
  }

  .text-field {
    &__input {
      font-size: 18px;
      line-height: 32px;
    }

    &--checklist {
      height: 40px;

      .checkbox {
        padding-left: 20px;
      }
    }

    &--list {
      height: 40px;
    }

    &--text {
      padding: 0 16px;
    }
  }
}

@media (max-width: $breakpoint-xl) {
  .rich-text {
    &__input {
      font-size: 16px;
      min-height: 36px;

      &-text {
        line-height: 22px;
      }
    }
  }

  .add-button {
    height: 36px;
    padding: 0 8px;
  }

  .text-field {
    &__input {
      font-size: 16px;
      line-height: 32px;
    }

    &--checklist {
      padding: 0 16px;
      height: 36px;
    }

    &--list {
      padding: 0 16px;
      height: 36px;
    }

    &--text {
      padding: 0 16px;
    }

    &__icon {
      &--close {
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}
