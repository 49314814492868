@import '../../common/sass/variables';

.modal-confirm {
  padding: 0 !important;
  z-index: 99999;

  &__header {
    border-bottom: 1px solid $color-grey-l80;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    padding: 8px 30px;
    font-size: 32px;
  }

  &__content {
    padding: 24px 30px;

    &--container {
      min-height: 100px;
    }
  }

  &__description {
    margin-bottom: 36px;
    font-size: 24px;
    line-height: 32px;
  }

  &__action {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin: 8px 0;

    &-button {
      height: 50px;
      padding: 0 36px;
      border-width: 1px;

      & span {
        font-size: 24px;
        font-family: Arial, Helvetica, sans-serif;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-xxl) {
  .modal-confirm {
    max-width: 500px;

    &__header {
      padding: 8px 24px;
      font-size: 28px;
    }

    &__content {
      padding: 24px;
    }

    &__description {
      margin-bottom: 28px;
      font-size: 20px;
      line-height: 28px;
    }

    &__action {
      gap: 12px;

      &-button {
        height: 40px;
        padding: 0 28px;

        & span {
          font-size: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-xl) {
  .modal-confirm {
    max-width: 450px;

    &__header {
      padding: 8px 20px;
      font-size: 24px;
    }

    &__content {
      padding: 20px;
    }

    &__description {
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 24px;
    }

    &__action {
      gap: 12px;

      &-button {
        height: 36px;
        padding: 0 24px;

        & span {
          font-size: 16px;
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-lg) {
  .modal-confirm {
    max-width: 400px;

    &__header {
      padding: 8px 16px;
      font-size: 20px;
    }

    &__content {
      padding: 16px;
    }

    &__description {
      margin-bottom: 16px;
      font-size: 16px;
      line-height: 24px;
    }

    &__action {
      gap: 12px;

      &-button {
        height: 36px;
        padding: 0 24px;

        & span {
          font-size: 16px;
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-sm) {
  .modal-confirm {
    max-width: 400px;
    margin: 8px;

    &__header {
      padding: 8px 16px;
      font-size: 18px;
    }

    &__content {
      padding: 16px;
    }

    &__description {
      margin-bottom: 14px;
      font-size: 14px;
      line-height: 22px;
    }

    &__action {
      gap: 10px;

      &-button {
        height: 32px;
        padding: 0 20px;

        & span {
          font-size: 14px;
        }
      }
    }
  }
}
