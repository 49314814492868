@import '../../common/sass/variables';

.doc-viewer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;

  &__header {
    position: absolute;
    top: 60px;
    left: 2%;
    z-index: 1000;
    display: flex;
    width: 20%;
    align-items: center;
    gap: 8px;

    span {
      color: $color-white;
    }

    .doc-viewer__title {
      gap: 4px;
      width: 75%;
    }
  }

  &__close {
    background-color: $color-white;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  &__download {
    position: absolute;
    top: 60px;
    right: 2%;
    z-index: 1000;

    svg {
      width: 70px;
      height: 50px;
    }
  }

  &__delete {
    position: absolute;
    top: 60px;
    right: 2%;
    z-index: 1000;
    background: $color-white;
    height: unset;
    min-width: unset;
    border-radius: 50%;

    svg {
      width: 30px;
      height: 30px;
      padding: 10px;
    }
  }

  &__nav {
    position: fixed;
    top: 50%;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #fff;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 24px;
      height: 24px;
    }

    &--left {
      left: 3%;
    }

    &--right {
      right: 3%;
    }

    &--hidden {
      display: none;
    }
  }

  &__container {
    background-color: rgb(0 36 32 / 70%) !important;
    height: 100vh;
    position: relative;

    #header-bar {
      background-color: transparent;

      #file-name,
      #doc-nav-info,
      #doc-nav-prev,
      #doc-nav-next {
        display: none;
      }
    }

    #proxy-renderer {
      justify-content: center;

      #pdf-controls {
        width: 50%;
        margin: auto;
        background-color: unset;
        border: unset;
        box-shadow: unset;
      }

      #image-renderer {
        background-color: transparent;
        background-image: unset;
      }

      #txt-renderer {
        margin: 0;
        width: 50% !important;
        background-color: $color-white;
        height: fit-content;
        word-break: break-word;
      }

      .react-pdf__Document {
        align-items: center;
      }

      #pdf-page-wrapper,
      #msdoc-renderer {
        margin: 0;
        width: 50% !important;

        .react-pdf__Page__canvas {
          width: 100% !important;
          height: auto !important;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-xxl) {
  .doc-viewer {
    &__header {
      top: 50px;
    }

    &__title {
      position: fixed;
      top: 8px;
      left: 30%;
      width: 40% !important;
      display: flex;
      justify-content: center;

      .file-name__container {
        width: unset !important;
        overflow: hidden;

        .file-name__title {
          max-width: unset;
        }

        span {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }

    &__close {
      width: 40px;
      height: 40px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &__download {
      top: 50px;

      svg {
        width: 56px;
        height: 40px;
      }
    }

    &__delete {
      top: 50px;

      svg {
        width: 26px;
        height: 26px;
        padding: 8px;
      }
    }

    &__nav {
      width: 48px;
      height: 48px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &__container {
      #proxy-renderer {
        #pdf-controls {
          width: 60%;
        }
        #txt-renderer,
        #pdf-page-wrapper,
        #msdoc-renderer {
          width: 60% !important;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-xl) {
  .doc-viewer {
    &__close {
      width: 36px;
      height: 36px;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    &__download {
      svg {
        width: 48px;
        height: 36px;
      }
    }

    &__delete {
      svg {
        width: 24px;
        height: 24px;
      }
    }

    &__nav {
      width: 40px;
      height: 40px;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    &__container {
      #proxy-renderer {
        #pdf-controls {
          width: 70%;
        }
        #txt-renderer,
        #pdf-page-wrapper,
        #msdoc-renderer {
          width: 70% !important;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-md) {
  .doc-viewer {
    &__header {
      top: 40px;
    }

    &__close {
      width: 32px;
      height: 32px;

      svg {
        width: 12px;
        height: 12px;
      }
    }

    &__title {
      top: 5px;
    }

    &__download {
      top: 40px;

      svg {
        width: 40px;
        height: 32px;
      }
    }

    &__delete {
      svg {
        width: 22px;
        height: 22px;
        padding: 6px;
      }
    }

    &__nav {
      &--left {
        left: 2%;
      }

      &--right {
        right: 2%;
      }
    }

    &__container {
      #proxy-renderer {
        margin-top: 10px;
        #pdf-controls {
          width: 80%;
        }
        #txt-renderer,
        #pdf-page-wrapper,
        #msdoc-renderer {
          width: 80% !important;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .doc-viewer {
    &__header {
      top: 6px;
      left: 1%;

      div {
        gap: 4px;
        width: 75%;
      }
    }

    &__close {
      width: 28px;
      height: 28px;

      svg {
        width: 12px;
        height: 12px;
      }
    }

    &__download {
      position: absolute;
      top: 6px;
      right: 1%;
      width: 36px;
      height: 28px;

      svg {
        width: 36px;
        height: 28px;
      }
    }

    &__delete {
      position: absolute;
      top: 6px;
      right: 1%;

      svg {
        width: 18px;
        height: 18px;
        padding: 6px;
      }
    }

    &__nav {
      top: 6px;
      width: 28px;
      height: 28px;

      svg {
        width: 18px;
        height: 18px;
      }

      &--left {
        left: 15%;
      }

      &--right {
        right: 15%;
      }
    }

    &__container {
      #proxy-renderer {
        #pdf-controls {
          width: 95%;
        }
        #txt-renderer,
        #pdf-page-wrapper,
        #msdoc-renderer {
          width: 95% !important;

          .react-pdf__Page__canvas {
            width: 100% !important;
            height: auto !important;
          }
        }
      }
    }
  }
}
