@import '../../common/sass/variables';

.avatar {
  width: 48px;
  height: 48px;

  &--border {
    border-radius: 50%;
    border: 5px solid $color-white;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  }

  &--xs {
    width: 24px;
    height: 24px;
  }
  &--sm {
    width: 32px;
    height: 32px;
  }
  &--md {
    width: 48px;
    height: 48px;
  }
  &--lg {
    width: 64px;
    height: 64px;
  }

  &--xl {
    width: 70px;
    height: 70px;
  }

  &--rounded {
    border-radius: 50%;
  }

  @media (max-width: $breakpoint-xxl) {
    &--border {
      border-radius: 50%;
      border: 3px solid $color-white;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    }

    &--xs {
      width: 20px;
      height: 20px;
    }
    &--sm {
      width: 24px;
      height: 24px;
    }
    &--md {
      width: 32px;
      height: 32px;
    }
    &--lg {
      width: 48px;
      height: 48px;
    }

    &--xl {
      width: 62px;
      height: 62px;
    }
  }
}
