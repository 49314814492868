@import '../../common/sass/_variables.scss';

$sidebar-full-width: 360px;
$sidebar-width: 120px;

.sidebar {
  $root: &;
  z-index: 11;
  width: $sidebar-full-width;
  min-height: calc(100vh - 100px);
  background: $color-primary-l20;
  transition: width ease-in-out 0.25s;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  position: fixed;
  height: -webkit-fill-available;
  margin-top: 100px;

  ::-webkit-scrollbar {
    display: none;
  }

  &--collapsed {
    width: $sidebar-width;

    #{$root}__header {
      justify-content: center;
      padding: 0 var(--sp-6) 0 var(--sp-6);
    }
  }

  &__header {
    height: 120px;
    width: inherit;
    background: $color-white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 var(--sp-5) 0 20px;

    &__icon--collapsed {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 15px;

      svg {
        width: 45px;
        height: 45px;
      }
    }
  }

  &__search {
    button {
      top: 1px !important;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    input {
      width: 100% !important;
      height: 50px !important;
      font-size: 18px;
    }
  }

  &__body {
    padding-top: 10px;
    max-height: calc(100vh - 220px);
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

@media only screen and (max-width: $breakpoint-xxl) {
  .sidebar {
    width: 300px;
    min-height: calc(100vh - 80px);
    margin-top: 80px;

    &--collapsed {
      width: 100px;
    }

    &__header {
      height: 100px;

      &__icon--collapsed {
        svg {
          width: 40px;
          height: 40px;
        }
      }
    }

    &__search {
      button {
        svg {
          width: 16px;
          height: 16px;
        }
      }

      input {
        height: 45px !important;
        font-size: 16px;
      }
    }

    &__body {
      padding-top: 8px;
      max-height: calc(100vh - 180px);
    }
  }
}

@media only screen and (max-width: $breakpoint-xl) {
  .sidebar {
    width: 280px;

    &--collapsed {
      width: 90px;
    }

    &__header {
      height: 90px;

      &__icon--collapsed {
        svg {
          width: 32px;
          height: 32px;
        }
      }
    }

    &__search {
      button {
        top: 0 !important;

        svg {
          width: 16px;
          height: 16px;
        }
      }

      input {
        height: 40px !important;
        font-size: 14px;
      }
    }

    &__body {
      padding-top: 4px;
      max-height: calc(100vh - 170px);
    }
  }
}

@media only screen and (max-width: $breakpoint-md) {
  .sidebar {
    min-height: calc(100vh - 65px);
    margin-top: 65px;

    &__body {
      max-height: calc(100vh - 155px);
    }
  }
}

@media only screen and (max-width: $breakpoint-sm) {
  .sidebar {
    width: 100vw;

    &--collapsed {
      width: 0;

      .sidebar__header {
        padding: 0;
        &__icon--collapsed {
          display: none;
        }
      }
    }

    &__header {
      height: 60px;

      &__icon--collapsed {
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    &__search {
      width: 100%;

      button {
        top: -4px !important;

        svg {
          width: 14px;
          height: 14px;
        }
      }

      input {
        height: 32px !important;
        font-size: 14px;
      }
    }

    &__body {
      max-height: calc(100vh - 125px);
    }
  }
}
